// App.js
import React, { createContext, useState, useEffect, useCallback } from 'react';

import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { styleReset } from 'react95';
import original from 'react95/dist/themes/original';
import Moralis from 'moralis';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';

import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';

import './styles.scss';

import GlobalHeader from './components/GlobalHeader';
import Home from './components/Home';
import Solana from './components/Solana';
import Ethereum from './components/Ethereum';
import SolMint from './components/SolMint';
// import Solitaire from './components/Solitaire';
import TerminalLoadingScreen from './components/TerminalLoadingScreen';


export const WalletContext = createContext({
  wallet: null,
  selectedNfts: [],
  setSelectedNfts: () => {},
  oopsieImage: null, 
  setOopsieImage: () => {},
  oopsieLevel: 1,
  setOopsieLevel: () => {},
  nfts: [], 
  setNfts: () => {},
});

const ResetStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
  }
  body {
    background: ${({ theme }) => theme.desktopBackground};
    font-family: 'ms_sans_serif';
  }
`;

Moralis.start({
  apiKey: process.env.REACT_APP_MORALIS_API_KEY,
});

function App() {
  const [wallet, setWallet] = useState(null);
  const phantomAdapter = new PhantomWalletAdapter();
  const [loading, setLoading] = useState(localStorage.getItem('hasLoadedBefore') !== 'true');
  const [selectedNfts, setSelectedNfts] = useState([]);
  const [oopsieImage, setOopsieImage] = useState([]);
  const [oopsieLevel, setOopsieLevel] = useState([]);
  const [nfts, setNfts] = useState([]);

  const handleConnectPhantom = useCallback(async () => {
    try {
      await phantomAdapter.connect();
      setWallet(phantomAdapter);
    } catch (error) {
      console.error('Error connecting to Phantom Wallet:', error);
    }
  }, [phantomAdapter]);

  const handleDisconnect = useCallback(async () => {
    try {
      await wallet.disconnect();
      setWallet(null);
      console.log('Disconnected from Phantom Wallet');
    } catch (error) {
      console.error('Error disconnecting from Phantom Wallet:', error);
    }
  }, [wallet]);

  const handleFinishedLoading = useCallback(() => {
    setLoading(false);
    localStorage.setItem('hasLoadedBefore', 'true');
  }, []);

  useEffect(() => {
    if (phantomAdapter.connected) {
      handleConnectPhantom();
    }
  }, [handleConnectPhantom, phantomAdapter]);

  return (
    <ThemeProvider theme={original}>
      <ResetStyles />
      {loading ? (
        <TerminalLoadingScreen onFinished={handleFinishedLoading} />
      ) : (
        <Router>
          <WalletContext.Provider value={{ wallet, selectedNfts, setSelectedNfts, oopsieImage, setOopsieImage, oopsieLevel, setOopsieLevel, nfts, setNfts }} autoConnect>
            <GlobalHeader
              onConnect={handleConnectPhantom}
              onDisconnect={handleDisconnect}
              isConnected={wallet !== null}
              walletAddress={wallet && wallet.publicKey.toBase58()}
            />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/solana" element={<Solana />} />
              <Route path="/ethereum" element={<Ethereum />} />  
              <Route path="/sol-mint" element={<SolMint />} />
              {/* <Route path="/solitaire" element={<Solitaire />} /> */}
            </Routes>
          </WalletContext.Provider>
        </Router>
      )}
    </ThemeProvider>
  );
}

export default App;
