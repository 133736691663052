import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Window, WindowContent, WindowHeader, Button, Frame, Separator, Toolbar, NumberInput } from 'react95';
import OopsieFinalPreview from './windows/OopsieFinalPreview';
import { WalletContext } from '../App';

const SolMint = () => {
  console.log('SolMint render');
  let navigate = useNavigate();

  const { wallet, selectedNfts, setSelectedNfts, oopsieImage, setOopsieImage, oopsieLevel, setOopsieLevel } = useContext(WalletContext);
  const [oopsieToReceive, setOopsieToReceive] = useState(1000);
  const [mintCost, setMintCost] = useState(0.25);

  useEffect(() => {
    if (wallet == null || selectedNfts == null || selectedNfts.length <= 0) {
      navigate('/Solana');
      return;
    }
  }, [wallet, navigate]);

  //const selectedNfts = wallet ? wallet.selectedNfts : []; // Should be retrieved from context or prop

    useEffect(() => {
      console.log(oopsieLevel);
      if (!oopsieLevel || oopsieLevel == 0) {
            setOopsieLevel(1);
            setOopsieToReceive(1000);
            setMintCost(0.25);
        } else {
            setOopsieToReceive(oopsieLevel * 1000);
            setMintCost(oopsieLevel * 0.25);
        }
    }, [oopsieLevel, setOopsieLevel]);
    
  const mintNfts = () => {
    // Perform minting here
  }

  return (
    <div className="full-wrapper">
      <OopsieFinalPreview 
        image={selectedNfts} 
      />

      <Window className='window mint-details-window'>
        <WindowHeader className='window-title'>
          <span>mint-details.exe</span>
        </WindowHeader>

        <WindowContent className="mint-details-window-content">
          <p>NFTs being combined: {selectedNfts.length}</p>
          <p>Oopsie Level: {oopsieLevel}</p>
          <Frame>
            <NumberInput 
              defaultValue={1}
              min={1} 
              max={100} 
              // value={oopsieLevel} 
              // onChange={e => console.log(e)}
              onChange={e => setOopsieLevel(parseInt(e, 10))}
              style={{ width: 100 }}
            />
            
          </Frame>
          <p>$OOPSIE to receive: {oopsieToReceive}</p>
          <p>Mint cost: {mintCost} SOL</p>

          <Button onClick={mintNfts}>Mint</Button>
        </WindowContent>
      </Window>
    </div>
  );
};

export default SolMint;
