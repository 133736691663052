import React from 'react';
import {
  Box,
  Image,
  Center,
  VStack,
  Text
} from '@chakra-ui/react';
import { Window, WindowContent, WindowHeader, ScrollView } from 'react95';

const SelectedNFTs = ({ selectedNfts, handleSelect }) => {
  return (
    <Window className='window horizontal-preview-window'>
      <WindowHeader className='window-title'>
        <span>selected-nfts.exe</span>
      </WindowHeader>
      <WindowContent>

      <ScrollView style={{ width: '100%', height: '100px' }}>
        <div className="selected-nfts-view">
          {selectedNfts.map((nft, index) => {
            return (
              <Box
              key={nft.mint}
              as="div"
              onClick={() => handleSelect(nft)}
              _hover={{ cursor: "pointer", opacity: 0.5 }}
              position="relative"
              >
                <VStack
                  as="button"
                  onClick={() => handleSelect(nft)}
                  _hover={{ cursor: "pointer", opacity: 0.5 }}
                  position="relative"
                >
                  <Image
                    boxSize="50px"
                    objectFit="cover"
                    src={nft.imageURL}
                    alt={`NFT ${nft.mint}`}
                  />
                  <Center
                    position="absolute"
                    top="0"
                    right="0"
                    bottom="0"
                    left="0"
                    bgColor="rgba(0, 0, 0, 0.5)"
                    opacity="0"
                    _hover={{ opacity: "1" }}
                  >
                    <Text fontSize="xs" color="white">
                      Remove
                    </Text>
                  </Center>
                </VStack>
              </Box>
            );
          })}
        </div>
      </ScrollView>

      </WindowContent>
    </Window>
  );
};

export default SelectedNFTs;
