import {React , useContext} from "react";
import { Link } from 'react-router-dom';
import {
  Button as BaseButton,
  Frame as BaseFrame,
  Toolbar,
  Window as BaseWindow,
  WindowContent,
  WindowHeader as BaseWindowHeader,
  Button,
  Separator
} from 'react95';
import { WalletContext } from '../App';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// Style the components using styled-components
const Window = styled(BaseWindow)`
  width: 400px;
  min-height: 200px;
  & + & {
    margin: 2rem;
  }
`;

const WindowHeader = styled(BaseWindowHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// Styling for the close-icon
const CloseIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: -1px;
  margin-top: -1px;
  transform: rotateZ(45deg);
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.materialText};
  }
  &:before {
    height: 100%;
    width: 3px;
    left: 50%;
    transform: translateX(-50%);
  }
  &:after {
    height: 3px;
    width: 100%;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Frame = styled(BaseFrame)`
  display: block;
  margin: 0.25rem;
  height: 31px;
  line-height: 31px;
  padding-left: 0.25rem;
`;

const Home = () => {
  const context = useContext(WalletContext);
  const navigate = useNavigate();

  return (
    <div className="full-wrapper">
      <Window className='window centered-modal'>
        <WindowHeader className='window-title'>
          <span>oopsies.exe</span>
          {/* <BaseButton>
            <CloseIcon className='close-icon' />
          </BaseButton> */}
        </WindowHeader>
        <WindowContent>
          <p>
            Seems like you made some Oopsies. Combine those worthless NFTs into a single, even more worthless NFT.
          </p>
          {context.wallet &&
            <div className="button-wrapper">
              <br></br>
              <p>Wallet connected!</p>
              <br></br>
              <Button onClick={() => navigate("/solana")} style={{marginRight: 10}}>
                  Solana NFTs
              </Button>
              <Button onClick={() => navigate("/ethereum")}>
                  Ethereum NFTs
              </Button>
            </div>
          }

        </WindowContent>
        <Frame variant='well' className='footer'>
          Use the start menu to begin.
        </Frame>
      </Window>
    </div>
  );
};

export default Home;
