import React, { useState, useEffect, useContext } from 'react';
import { Text } from '@chakra-ui/react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Box, Image } from "@chakra-ui/react";

import { Frame, Window, WindowContent, WindowHeader, Button, ScrollView, GroupBox, TreeView, Hourglass } from 'react95';
import { WalletContext } from '../App';
import Moralis from 'moralis';
import axios from 'axios';
import { SolNetwork } from '@moralisweb3/common-sol-utils';

import OopsiePreview from './windows/OopsiePreview';
import SelectedNFTs from './windows/SelectedNFTs';
import alertIcon from '../images/warning.png';

const Solana = () => {
  const { wallet, selectedNfts, setSelectedNfts, oopsieImage, setOopsieImage, oopsieLevel, setOopsieLevel, nfts, setNfts } = useContext(WalletContext);
  //const [nfts, setNfts] = useState([]);
  // const [selectedNfts, setSelectedNfts] = useContext(WalletContext);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState(0);
  const [blendMode, setBlendMode] = useState('overlay');
  const [voronoiRedrawCounter, setVoronoiRedrawCounter] = useState(0);
  const [generatingPreview, setGeneratingPreview] = useState(false);
  const [groupedNfts, setGroupedNfts] = useState({});

  const [collectionCache, setCollectionCache] = useState(() => {
    const localData = localStorage.getItem('collectionCache');
    return localData ? JSON.parse(localData) : {};
  });

  const fetchData = async (url, errorMessage) => {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error(errorMessage, error);
    }
  };


  const fetchCollectionInfo = async (nft) => {
    const cacheKey = nft.updateAuthority;
    const response = await fetchData(`/api/cached-collection-info?updateAuthority=${cacheKey}`, 'Error fetching collection info:');
    const collectionInfo = response ? {
      collectionName: response.collectionName || nft.name.split('#')[0],
      symbol: response.symbol,
      floorPrice: response.floorPrice
    } : {
      collectionName: nft.name.split('#')[0],
      symbol: null,
      floorPrice: null
    };
    setCollectionCache(prevCache => ({ ...prevCache, [cacheKey]: collectionInfo }));
    return collectionInfo;
  };

  const groupNftsByUpdateAuthority = async (nfts) => {
    const groupedNfts = nfts.reduce((acc, nft) => {

      //const collectionName = nft.collectionName || nft.symbol || 'Unknown Collection';
      const collectionName = nft.name !== '' ? nft.name.split('#')[0] : nft.symbol;
      const updateAuthority = nft.metaplex.updateAuthority;
      if (!acc[updateAuthority]) {
        acc[updateAuthority] = { collectionName, nfts: [], floorPrice: null };
      }
      acc[updateAuthority].nfts.push(nft);
      return acc;
    }, {});
  
    return groupedNfts;
  };

  useEffect(() => {
    if (nfts?.length > 0) {
      groupNftsByUpdateAuthority(nfts).then((grouped) => {
        const transformedGroup = Object.values(grouped).reduce((acc, curr) => {
          if (!acc[curr.collectionName]) {
            acc[curr.collectionName] = { nfts: [], floorPrice: null };
          }
          acc[curr.collectionName].nfts.push(...curr.nfts);
          acc[curr.collectionName].floorPrice = curr.floorPrice;
          return acc;
        }, {});
        setGroupedNfts(transformedGroup);
      });
    }
  }, [nfts]);

  useEffect(() => {
    if (!wallet) return;
    
    const fetchNFTs = async () => {
      const address = wallet.publicKey.toBase58();
    
      let response;
      try {
        response = await Moralis.SolApi.account.getNFTs({
          address,
          network: SolNetwork.MAINNET,
        });
      } catch (error) {
        console.log('Failed to retrieve NFTs:', error);
        return;
      }

      const nfts = (await Promise.all(
        response.jsonResponse.map(async (nft) => {
          let metadata;
          try {
            metadata = await fetchMetadata(nft.mint);
          } catch (error) {
            console.log('Failed to fetch metadata for NFT:', error);
            return null;
          }
    
          const metadataUri = metadata.metaplex?.metadataUri;
          const metaPlexResponse = await fetch(metadataUri);
          const metaPlex = await metaPlexResponse.json();
    
          const updateAuthority = metadata.metaplex.updateAuthority;
          const isSelected = selectedNfts.some(selectedNft => selectedNft.mint === nft.mint);

          return {
            ...nft,
            ...metadata,
            imageURL: metaPlex.image || 'https://via.placeholder.com/100',
            selected: false,
            // collectionName: await collectionNamePromise,
          };
        })
      )).filter(nft => nft !== null);
    
      setNfts(nfts);
      setLoading(false);
    };    
    
    const fetchMetadata = async (address) => {
      const network = SolNetwork.MAINNET;
  
      let response;
      try {
        response = await Moralis.SolApi.nft.getNFTMetadata({
          address,
          network,
        });
      } catch (error) {
        //console.log('Failed to retrieve NFT metadata:', error);
        throw error;
      }
  
      return response.toJSON();
    };
  
    if (nfts && nfts.length > 0) {
      setLoading(false);
    } else {
      fetchNFTs();
    }

  }, [wallet, nfts]);
  
    
  useEffect(() => {
    if (displayMode === 1) {
      setBlendMode("overlay");
    }
  }, [displayMode]);
  
  const handleSelect = (nft) => {
    const index = nfts.findIndex((item) => item === nft);
    if (index !== -1) toggleSelect(nft, index);
  };

  const toggleSelect = (nft, index) => {
    const updatedNfts = [...nfts];
    updatedNfts[index].selected = !updatedNfts[index].selected;
    setNfts(updatedNfts);
  
    if (updatedNfts[index].selected) {
      setSelectedNfts((prev) => {
        // Only add the NFT if it's not already in the array.
        if (!prev.some((selectedNft) => selectedNft.mint === nft.mint)) {
          return [...prev, nfts[index]];
        }
        // Return the existing array if the NFT is already there.
        return prev;
      });
    } else {
      setSelectedNfts((prev) => prev.filter((selectedNft) => selectedNft.mint !== nft.mint));
    }
  };
  
  
  useEffect(() => {
    localStorage.setItem('collectionCache', JSON.stringify(collectionCache));
  }, [collectionCache]);

  if (!wallet) {
    return (
      <Frame
        className='centered-alert'
        variant='outside'
        shadow
        style={{ padding: '0.5rem', lineHeight: '1.5', width: 600 }}
      >
        <div className="small-icon" style={{ backgroundImage: `url(${alertIcon})`, marginRight: '5px' }} />
        Connect wallet
      </Frame>

    );
  }

  return (
    <div className="full-wrapper">

      {/* Oopsie preview window */}
      <OopsiePreview 
        selectedNfts={selectedNfts} 
        displayMode={displayMode} 
        setDisplayMode={setDisplayMode} 
        blendMode={blendMode} 
        setBlendMode={setBlendMode} 
        generatingPreview={generatingPreview}
        voronoiRedrawCounter={voronoiRedrawCounter}
        setVoronoiRedrawCounter={setVoronoiRedrawCounter}
      />

      {/* NFT main window */}
      <Window className='window main-nft-content-window'>
        <WindowHeader className='window-title'>
          <span>my-solana-nfts.exe</span>
        </WindowHeader>

        <WindowContent className="main-window-content">
          <ScrollView className="main-scroll-view" style={{ width: "100%", height: "495px", maxHeight: "calc(100vh - 357px)" }}>
            <GroupBox className="my-nfts-groupbox">

            {loading ? (
              <>
              <div className="loading-wrapper">
                <Hourglass size={32} style={{ margin: 20 }} />
                <p>Loading...</p>
              </div>
              </>

              ) : nfts?.length === 0 ? (
                
                <Text fontSize="8px">
                  {wallet ? `No NFTs found in ${wallet.publicKey.toBase58()}` : 'No wallet connected'}
                </Text>
              
              ) : (

              <>
                {Object.entries(groupedNfts).map(([collectionName, collectionNfts]) => (
                  <Accordion allowToggle key={collectionName}>
                    <AccordionItem>
                      <h2>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <AccordionButton className="accordion-button">
                            <Box flex="1" textAlign="left">
                              {collectionName}
                            </Box>
                          </AccordionButton>
                        </Box>
                      </h2>
                      <AccordionPanel className="accordion-panel" pb={4}>
                        {collectionNfts.nfts.map((nft) => (
                          <Box
                            key={nft.mint}
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            className={`accordion-box ${nft.selected ? 'selected' : ''}`}
                            mb={2}
                            cursor="pointer"
                            onClick={() => handleSelect(nft)}
                          >
                            <Box className="selected-state" >{nft.selected ? 'x' : ' '}</Box>
                            <Image boxSize="50px" src={nft.imageURL} alt={nft.name} mr={2} />
                            <Box>
                              {nft.name}
                            </Box>
                          </Box>
                        ))}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                ))}
            </>)}
            </GroupBox>
          </ScrollView>
        </WindowContent>
        <Frame variant='well' className='footer'>
          Select the NFTs you'd like to combine into your Oopsie. 
        </Frame>
      </Window>


      {/* Selected NFTs horizontal window */}

      <SelectedNFTs 
        selectedNfts={selectedNfts} 
        handleSelect={handleSelect} 
        />

    </div>
  );
};

export default Solana;