import React, { useState, useEffect } from 'react';
import { Image } from '@chakra-ui/react';
import { Hourglass } from 'react95';


const OverlayImage = ({ nfts, blendMode }) => {
  const [dataUrl, setDataUrl] = useState('');
  const [imgElements, setImgElements] = useState([]);

  useEffect(() => {
    const images = nfts.map((nft) => {
      const img = new window.Image();
      img.crossOrigin = 'anonymous';
      img.src = nft.imageURL;
      return img;
    });

    setImgElements(images);
  }, [nfts]);

  useEffect(() => {
    if (imgElements.length !== nfts.length) return;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 300;
    canvas.height = 300;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    imgElements.forEach((img) => {
      ctx.globalCompositeOperation = blendMode;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    });

    const dataUrl = canvas.toDataURL('image/png');
    setDataUrl(dataUrl);
  }, [nfts, blendMode, imgElements]);

  if (nfts.length === 0) {
    return <Image src="/logo512.png" />;
  }
  
  return (
    <>
      {dataUrl === '' ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', backgroundColor: 'black' }}>
          <Hourglass size={32} style={{ margin: 20 }} />
          <p style={{ color: 'white' }}>Generating...</p>
        </div>
      ) : (
        <Image src={dataUrl} />
      )}
    </>
  );
};

export default OverlayImage;
