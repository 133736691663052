import React, { useEffect, useRef, useState } from 'react'; 
import { Delaunay } from 'd3-delaunay';
import { Image } from '@chakra-ui/react';
import { Hourglass } from 'react95';

const loadImage = (src) => {
  return new Promise((resolve) => {
    const img = new window.Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => resolve(img);
    img.src = src;
  });
};

const getRandomPoints = (numImages, width, height, cellSize) => {
  const points = [];
  const padding = 0.5 * cellSize;

  for (let i = 0; i < numImages; i++) {
    points.push([
      padding + Math.floor(Math.random() * (width - 2 * padding)),
      padding + Math.floor(Math.random() * (height - 2 * padding))
    ]);
  }
  return points;
};

const calculateCellSize = (numImages) => {
  const baseSize = 100;
  const scaleFactor = Math.sqrt(numImages / 8);
  return baseSize / scaleFactor;
};

const getCellBounds = (cell, cellSize) => {
  const [minX, minY] = [Math.min(...cell.map((pt) => pt[0])), Math.min(...cell.map((pt) => pt[1]))];
  const [maxX, maxY] = [Math.max(...cell.map((pt) => pt[0])), Math.max(...cell.map((pt) => pt[1]))];

  const scaleFactor = Math.max((maxX - minX) / cellSize, (maxY - minY) / cellSize) + 0.5;
  
  return [minX, minY, maxX, maxY, scaleFactor];
};

const VoronoiImage = ({ nfts, width = 300, height = 300, redrawCounter  }) => {
  const canvasRef = useRef(null);
  const [generatingPreview, setGeneratingPreview] = useState(false); 


  useEffect(() => {
    const loadAllImages = async () => {
      return await Promise.all(nfts.map((nft) => loadImage(nft.imageURL)));
    };
  
    const drawVoronoi = async () => {
      setGeneratingPreview(true);

      if (!canvasRef.current) {
        setGeneratingPreview(false);
        return;
      }

      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
  
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const cellSize = calculateCellSize(nfts.length);
      const points = getRandomPoints(nfts.length, width, height, cellSize);
      const delaunay = Delaunay.from(points);
      const voronoi = delaunay.voronoi([0, 0, width, height]);
  
      const images = await loadAllImages();
  
      for (let i = 0; i < nfts.length; i++) {
        ctx.save();
        ctx.beginPath();
        voronoi.renderCell(i, ctx);
        ctx.clip();
  
        const cellPolygon = voronoi.cellPolygon(i);
        const [x0, y0, x1, y1, scaleFactor] = getCellBounds(cellPolygon, cellSize);
        const cellWidth = x1 - x0;
        const cellHeight = y1 - y0;
  
        const img = images[i];
        const imgAspectRatio = img.width / img.height;
        let imgWidth, imgHeight;
  
        if (imgAspectRatio > 1) {
          imgWidth = cellWidth;
          imgHeight = imgWidth / imgAspectRatio;
        } else {
          imgHeight = cellHeight;
          imgWidth = imgHeight * imgAspectRatio;
        }
  
        ctx.drawImage(
          img,
          points[i][0] - (imgWidth * scaleFactor) / 2,
          points[i][1] - (imgHeight * scaleFactor) / 2,
          imgWidth * scaleFactor,
          imgHeight * scaleFactor
        );
        ctx.restore();
      }
    };
  
    drawVoronoi();
    setGeneratingPreview(false);
  }, [nfts, width, height, redrawCounter]);  

  if (nfts.length === 0) {
    return <Image src="/logo512.png" />;
  }

  return (
    <>
      {generatingPreview ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', backgroundColor: 'black' }}>
          <Hourglass size={32} style={{ margin: 20 }} />
          <p style={{ color: 'white' }}>Generating...</p>
        </div>
      ) : (
      <canvas ref={canvasRef} />
      )}
    </>
  );
};

export default VoronoiImage;
