import React, { useState, useEffect, useContext } from 'react';
import { Image } from '@chakra-ui/react';
import { Hourglass } from 'react95';
import { WalletContext } from '../App';

const MosaicImage = ({ nfts }) => {
  const [dataUrl, setDataUrl] = useState('');
  const { setOopsieImage } = useContext(WalletContext);

  useEffect(() => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const colCount = Math.ceil(Math.sqrt(nfts.length));
    const rowCount = Math.ceil(nfts.length / colCount);
    const tileSize = 300 / colCount;

    canvas.width = 300;
    canvas.height = 300;

    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const lastRowCount = nfts.length % colCount;
    const lastRowOffset = lastRowCount ? (canvas.width - lastRowCount * tileSize) / 2 : 0;

    const imgElements = nfts.map((nft) => {
      const img = new window.Image();
      img.crossOrigin = 'anonymous';
      img.src = nft.imageURL;
      return img;
    });

    let imagesLoaded = 0;

    imgElements.forEach((img, index) => {
      img.onload = () => {
        imagesLoaded++;

        const row = Math.floor(index / colCount);
        const isLastRow = row === rowCount - 1;

        const x = (index % colCount) * tileSize + (isLastRow ? lastRowOffset : 0);
        const y = row * tileSize;
        ctx.drawImage(img, x, y, tileSize, tileSize);

        if (imagesLoaded === imgElements.length) {
          const dataUrl = canvas.toDataURL('image/png');
          setDataUrl(dataUrl);
          setOopsieImage(dataUrl);
        }
      };
    });
  }, [nfts, setOopsieImage]);

  if (nfts.length === 0) {
    return <Image src="/logo512.png" />;
  }

  if (dataUrl === '') {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', backgroundColor: 'black' }}>
        <Hourglass size={32} style={{ margin: 20 }} />
        <p style={{ color: 'white' }}>Generating...</p>
      </div>
    );
  }

  return <Image src={dataUrl} />;
};

export default MosaicImage;
