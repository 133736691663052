import React, { useState, useEffect } from 'react';
import './TerminalLoadingScreen.scss';

// const asciiArt = `
//   ______    ______   _______    ______   ______  ________   ______  
//  /      \\  /      \\ |       \\  /      \\ |      \\|        \\ /      \\ 
// |  $$$$$$\\|  $$$$$$\\| $$$$$$$\\|  $$$$$$\\ \\$$$$$$| $$$$$$$$|  $$$$$$\\
// | $$  | $$| $$  | $$| $$__/ $$| $$___\\$$  | $$  | $$__    | $$___\\$$
// | $$  | $$| $$  | $$| $$    $$ \\$$    \\   | $$  | $$  \\    \\$$    \\ 
// | $$  | $$| $$  | $$| $$$$$$$  _\\$$$$$$\\  | $$  | $$$$$    _\\$$$$$$\\
// | $$__/ $$| $$__/ $$| $$      |  \\__| $$ _| $$_ | $$_____ |  \\__| $$
//  \\$$    $$ \\$$    $$| $$       \\$$    $$|   $$ \\| $$     \\ \\$$    $$
//   \\$$$$$$   \\$$$$$$  \\$$        \\$$$$$$  \\$$$$$$ \\$$$$$$$$  \\$$$$$$ 
                                                                    
                                                                    
// `;

const asciiArt = `
   ___   ___  ___  ___ ___ ___ ___ 
  / _ \\ / _ \\| _ \\/ __|_ _| __/ __|
 | (_) | (_) |  _/\\__ \\| || _|\\__ \\
  \\___/ \\___/|_|  |___/___|___|___/
                                    
`;

const lines = [
  <>Welcome to the Oopsies blockchain backend terminal!</>,
  <><span className="green">Found SCSI device(s) handled by BusLogic.o.</span></>,
  <><span className="cyan">Scanning for USB/Firewire devices...</span><span className="highlight">Done.</span></>,
  <><span className="cyan">Enabling </span><span className="highlight">DMA</span> <span className="cyan">acceleration for: hdc</span></>,
  <>
    <span className="green">Accessing Oopsie OS Kernal at</span>{" "}
    <span className="highlight">eight/six/seven/five/three/oh/niyiyine</span>
  </>,
  <><span className="green">Found backend filesystem</span></>,
  <><span className="cyan">Total memory found :</span><span className="yellow"> 128124 kb</span></>,
  <><span className="cyan">Creating <span className="yellow">/ramsdisk</span> (dynamic size=64000k) on <span className="highlight">shared memory</span>... Done.</span></>,
  <><span className="cyan">Creating directories and symlinks on ramdisk...Done</span></>,
  <><span className="cyan">Starting init process.</span></>,
  <>INIT version 1.69.420 booting</>,
  <><span className="green">Running Oopsies OS Kernel</span><span className="yellow"> 1.69.420 Build. 1337</span></>,
  <><span className="green">Logical processors found:</span><span className="yellow"> 1</span></>,
  <><span className="green">Found kernel anomaly at:</span><span className="yellow"> root/.blockchain/.rugs</span></>,
  <><span className="green">OUCHIE Bios found, activating modules:</span><span className="yellow"> web1 web2 web3</span></>,
  <><span className="cyan">Starting <span className="highlight">oopsdev </span><span className="green">hot-plug hardware detection... </span>Started.</span></>,
  <>Autoconfiguring devices... /</>,
];

const TerminalLoadingScreen = ({ onFinished }) => {
    const [progress, setProgress] = useState({ lineIndex: 0, charIndex: 0 });
    const [showCursor, setShowCursor] = useState(true);
  
    const getCharacters = (line) => {
      if (typeof line === 'string') {
        return line.split('');
      }
  
      const characters = [];
      React.Children.forEach(line.props.children, (child) => {
        if (typeof child === 'string') {
          for (const ch of child.split('')) {
            characters.push(ch);
          }
        } else {
          characters.push(child);
        }
      });
      return characters;
    };
  
    useEffect(() => {
      const typeCharacter = () => {
        if (progress.lineIndex < lines.length) {
          const line = lines[progress.lineIndex];
          const characters = getCharacters(line);
  
          if (progress.charIndex < characters.length) {
            setShowCursor(true);
            setProgress((prevProgress) => ({
              ...prevProgress,
              charIndex: prevProgress.charIndex + 1,
            }));
            setTimeout(() => {
              setShowCursor(false);
            }, 50);
          } else {
            setShowCursor(true);
            setTimeout(() => {
              setShowCursor(false);
              setProgress((prevProgress) => ({
                lineIndex: prevProgress.lineIndex + 1,
                charIndex: 0,
              }));
            }, 1000); 
          }
        } else {
          setTimeout(onFinished, 1000);
        }
      };
  
      const typingTimeout = setTimeout(typeCharacter, 10); 
      return () => clearTimeout(typingTimeout);
    }, [progress, onFinished, showCursor]);
  
    const visibleLines = lines.slice(0, progress.lineIndex + 1).map((line, index) => {
      if (index < progress.lineIndex) {
        return getCharacters(line);
      } else if (index === progress.lineIndex) {
        return getCharacters(line).slice(0, progress.charIndex);
      } else {
        return [];
      }
    });
  
    return (
      <div className="terminal-wrapper">
        <div className="terminal-logo">
          <pre>{asciiArt}</pre>
        </div>
        <div className="terminal-loading-screen">
          <pre>
            {visibleLines.map((line, index) => (
              <div key={index}>
                {line.map((element, charIndex) => (
                  <React.Fragment key={charIndex}>{element}</React.Fragment>
                ))}
                {index === progress.lineIndex && showCursor && (
                  <span className="cursor">&nbsp;</span>
                )}
              </div>
            ))}
          </pre>
        </div>
      </div>
    );
  };
  
  export default TerminalLoadingScreen;
