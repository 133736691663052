import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import { WalletContext } from '../../App';

import {
  Window, WindowContent, WindowHeader, Button, Monitor, Frame, Hourglass, Toolbar, Select, MenuList, MenuListItem
} from 'react95';

const OopsieFinalPreview = () => {
  const { wallet, selectedNfts, setSelectedNfts, oopsieImage, setOopsieImage, oopsieLevel, setOopsieLevel } = useContext(WalletContext);
  const navigate = useNavigate();

  return (
    <Window className='window oopsie-preview-window'>
      <WindowHeader className='window-title'>
        <span>oopsies-preview.exe</span>
      </WindowHeader>
      <Toolbar>
        <Button 
          variant='menu' 
          size='sm' 
          onClick={() => navigate("/solana")}
        >  
          Edit
        </Button>
      </Toolbar>
      <WindowContent>
      <div className="image-preview">
        <Monitor className="monitor-preview" style={{height : "195px"}} backgroundStyles={{ background: 'blue' }}>
            <Image src={oopsieImage} />
        </Monitor>
      </div>
      </WindowContent> 
      <Frame variant='well' className='footer'>
          Edit style in view menu 
        </Frame>
    </Window>
  )
}

export default OopsieFinalPreview;
