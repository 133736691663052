import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Window, WindowContent, WindowHeader, Button, Monitor, Frame, Hourglass, Toolbar, Select, MenuList, MenuListItem
} from 'react95';
import MosaicImage from '../MosaicImage';
import OverlayImage from '../OverlayImage';
import VoronoiImage from '../VoronoiImage';

const OopsiePreview = ({ selectedNfts, setDisplayMode, displayMode, setBlendMode, blendMode, voronoiRedrawCounter, setVoronoiRedrawCounter, generatingPreview }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const blendModeOptions = [
    { label: 'Overlay', value: 'overlay' },
    { label: 'Darken', value: 'darken' },
    { label: 'Lighten', value: 'lighten' },
    { label: 'Color Dodge', value: 'color-dodge' },
    { label: 'Color Burn', value: 'color-burn' },
    { label: 'Hard Light', value: 'hard-light' },
    { label: 'Soft Light', value: 'soft-light' },
    { label: 'Difference', value: 'difference' }
  ];

  let navigate = useNavigate();

  const handleMenuClick = (mode) => {
    setDisplayMode(mode);
    setMenuVisible(false);
  }

  return (
    <Window className='window oopsie-preview-window'>
      <WindowHeader className='window-title'>
        <span>oopsies-preview.exe</span>
      </WindowHeader>
      <Toolbar>
        <Button 
          variant='menu' 
          size='sm' 
          disabled={selectedNfts.length <= 0} 
          onClick={() => setMenuVisible(!menuVisible)} 
        >  
          View
        </Button>
        {menuVisible && (
          <MenuList className="dropdown-menu">
            <MenuListItem onClick={() => handleMenuClick(0)}>
              {displayMode === 0 && '> '}Grid
            </MenuListItem>
            <MenuListItem onClick={() => handleMenuClick(1)}>
              {displayMode === 1 && '> '}Blend
            </MenuListItem>
            <MenuListItem onClick={() => handleMenuClick(3)}>
              {displayMode === 3 && '> '}Voronoi
            </MenuListItem>
          </MenuList>
        )}
        <Button 
          variant='menu' 
          size='sm' 
          disabled={selectedNfts.length < 2} 
          onClick={() => navigate("/sol-mint")}
        >  
          Mint
        </Button>
      </Toolbar>
      <WindowContent>
      <div className="image-preview">
      {generatingPreview && <Hourglass size={32} />}
      
        <Monitor className="monitor-preview" style={{height : "195px"}} backgroundStyles={{ background: 'blue' }}>
            {displayMode === 0 ? (
            <MosaicImage nfts={selectedNfts} />
          ) : displayMode === 1 ? (
            <OverlayImage nfts={selectedNfts} blendMode={blendMode} />
          ) : (
            <VoronoiImage nfts={selectedNfts} redrawCounter={voronoiRedrawCounter} />
          )}
        </Monitor>
      </div>

      {selectedNfts.length > 0 ? (
        <>          
        <br />
        {displayMode === 1 && (
          <>
            Blend Mode
            <Select
              defaultValue={blendMode}
              options={blendModeOptions}
              onChange={(option) => setBlendMode(option.value)}
              width={200}
            />
            </>
        )}

        {displayMode === 3 && (
          <Button
            onClick={() => setVoronoiRedrawCounter(voronoiRedrawCounter + 1)}
          >
            Redraw Voronoi
          </Button>
        )}

      </>
      ) : (
        <>
          <div className="full-text-wrapper centered-text">
            Select NFTs to begin
          </div>
        </>
      )}

      </WindowContent> 
      <Frame variant='well' className='footer'>
          Edit style in view menu 
        </Frame>
    </Window>
  )
}

export default OopsiePreview;
